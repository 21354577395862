import { FC, ReactNode, useState } from 'react'

import * as AlertDialog from '@radix-ui/react-alert-dialog'
import { Flex } from '@radix-ui/themes'
import { useEventListener } from 'ahooks'
import CloseIcon from 'assets/images/icon-close.svg?react'
import cx from 'clsx'
import { Button } from 'ui/Button'
import { Loader } from 'ui/Loader'

import classes from './Dialog.module.scss'

interface PropsDialog {
  trigger?: ReactNode
  title?: string
  children?: ReactNode
  isOpen?: boolean
  setIsOpen?: (isOpen: boolean) => void
  description?: ReactNode
  returnArrow?: ReactNode
  className?: string
  classNameDescription?: string
  isCloseIcon?: boolean
  isLoading?: boolean
}

export const Dialog: FC<PropsDialog> = ({
  isOpen,
  setIsOpen,
  trigger,
  title,
  children,
  description,
  returnArrow,
  className,
  classNameDescription,
  isCloseIcon = true,
  isLoading,
}) => {
  const [open, setOpen] = useState(false)
  useEventListener('closeDialog' as any, () => setOpen(false))

  return (
    <div className={classes.container}>
      <AlertDialog.Root onOpenChange={setIsOpen ? setIsOpen : setOpen} open={isOpen === undefined ? open : isOpen}>
        <AlertDialog.Trigger asChild>{trigger}</AlertDialog.Trigger>
        <AlertDialog.Portal container={document.body.querySelector('#portal2') as HTMLElement}>
          <AlertDialog.Overlay
            className={classes.alertDialogOverlay}
            data-stop-propagation="true"
            onClick={() => {
              if (setIsOpen) {
                setIsOpen(false)
              } else {
                setOpen(false)
              }
            }}
          />
          <AlertDialog.Content className={cx(classes.alertDialogContent, className)} data-stop-propagation="true">
            {isLoading && <Loader />}
            {!isLoading && (
              <>
                <Flex justify="between">
                  <Flex align="center" gap="4">
                    {returnArrow}
                    {title && <AlertDialog.Title className={classes.alertDialogTitle}>{title}</AlertDialog.Title>}
                  </Flex>
                  {isCloseIcon && (
                    <Button
                      className={classes.iconClose}
                      color="gray"
                      onClick={() => {
                        if (setIsOpen) {
                          setIsOpen(false)
                        } else {
                          setOpen(false)
                        }
                      }}
                      variant="soft"
                    >
                      <CloseIcon />
                    </Button>
                  )}
                </Flex>
                {description && (
                  <AlertDialog.Description asChild>
                    <div className={cx(classes.alertDialogDescription, classNameDescription)}>{description}</div>
                  </AlertDialog.Description>
                )}
                {children}
              </>
            )}
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>
    </div>
  )
}
